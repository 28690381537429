body {
  margin: 0;
  padding: 0;
  background-color: var(--bs-gray-100);
}

body, .btn, .navbar, .card, .modal, .dropdown-menu {
  font-family: 'Inter', sans-serif !important;
}

.shadow-md {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.brand-button {
  color: white !important;
  background-color: #612dae !important;
}

.brand-button-outline {
  color: #612dae !important;
  background-color: transparent !important;
  border: 1px solid #612dae !important;
}

.brand-button-outline:hover {
  color: #fff !important;
  background-color: #612dae !important;
  border: 1px solid #612dae !important;
  text-decoration: none !important;
}

.text-brand-color{
  color: #612dae !important;
}

.font-small {
  font-size: 0.75rem !important;
}

.selected {
  border: 2px solid #612dae !important;
}

.shepherd-button {
  background: #fff;
  border-top: solid 4px #16202d;
  border-radius: 0;
  color: #16202d;
  display: flex;
  flex-grow: 1;
  font-family: GT Pressura,sans-serif;
  font-size: 1rem;
  justify-content: center;
  margin: 0;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase
}

.shepherd-button:hover {
  background: #16202d;
  color: #fff
}

.shepherd-button.shepherd-button-secondary {
  background: #cad5d5
}

.shepherd-button.shepherd-button-secondary:hover {
  color: #cad5d5;
  background: #16202d
}

.shepherd-cancel-icon {
  font-family: GT Pressura,sans-serif
}

.shepherd-element {
  border: solid 4px #16202d
}

.shepherd-element,.shepherd-header,.shepherd-footer {
  border-radius: 0
}

.shepherd-element .shepherd-arrow {
  border-width: 0;
  height: auto;
  width: auto
}

.shepherd-arrow:before {
  display: none
}

.shepherd-element .shepherd-arrow:after {
  /*content: url("https://images.artwand.ai/arrow.svg");*/
  display: inline-block
}

.shepherd-element[data-popper-placement^=top] .shepherd-arrow,.shepherd-element.shepherd-pinned-top .shepherd-arrow {
  bottom: -35px
}

.shepherd-element[data-popper-placement^=top] .shepherd-arrow:after,.shepherd-element.shepherd-pinned-top .shepherd-arrow:after {
  transform: rotate(270deg)
}

.shepherd-element[data-popper-placement^=bottom] .shepherd-arrow {
  top: -35px
}

.shepherd-element[data-popper-placement^=bottom] .shepherd-arrow:after {
  transform: rotate(90deg)
}

.shepherd-element[data-popper-placement^=left] .shepherd-arrow,.shepherd-element.shepherd-pinned-left .shepherd-arrow {
  right: -35px
}

.shepherd-element[data-popper-placement^=left] .shepherd-arrow:after,.shepherd-element.shepherd-pinned-left .shepherd-arrow:after {
  transform: rotate(180deg)
}

.shepherd-element[data-popper-placement^=right] .shepherd-arrow,.shepherd-element.shepherd-pinned-right .shepherd-arrow {
  left: -35px
}

.shepherd-footer {
  padding: 0
}

.shepherd-footer button:not(:last-of-type) {
  border-right: solid 4px #16202d
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  margin-top: -7px
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: transparent;
  font-family: GT Pressura,sans-serif;
  padding-bottom: 0;
  padding-left: 2rem
}

.shepherd-has-title .shepherd-content .shepherd-header .shepherd-title {
  font-size: 1.2rem;
  text-transform: uppercase
}

.shepherd-text {
  font-size: 1.2rem;
  padding: 2rem
}

.shepherd-text a,.shepherd-text a:visited,.shepherd-text a:active {
  border-bottom: 1px dotted;
  border-bottom-color: #000000bf;
  color: #000000bf;
  text-decoration: none
}

.shepherd-text a:hover,.shepherd-text a:visited:hover,.shepherd-text a:active:hover {
  border-bottom-style: solid
}

/* src/styles.css */
.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.text-box {
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 60px; /* Minimum height for 3 lines */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  resize: none;
  outline: none;
}

.config-section {
  margin-bottom: 20px;
}

.model-grid {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.model-item {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.model-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.overlay-selected {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  color: rgb(0, 0, 0);
  width: 100%;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.overlay-text {
  padding: 10px;
  font-size: 1.2em;
}

.dimension-options {
  display: flex;
  gap: 10px;
}

.dimension-options button {
  padding: 10px;
  border-radius: 10px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
}

.generate-button {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.generated-cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.generated-card {
  flex: 1 1 calc(20% - 16px); /* 20% width minus the gap */
  box-sizing: border-box;
  max-width: calc(20% - 16px);
  text-align: center;
}

.generated-card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.generated-card-title {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
}

/* Add media query for mobile devices */
@media (max-width: 768px) {
  .generated-card {
    flex: 1 1 100%; /* Full width on mobile */
    max-width: 100%;
  }

  .overlay-text {
    padding: 1px;
    font-size: 0.8em;
  }
}